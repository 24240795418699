import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnDestroy,
  Output,
} from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalComponent } from "../configure-device-modal/configure-device-modal.component";
import { ApiService } from "src/app/core-module/services/api.service";
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { typeWithParameters } from "@angular/compiler/src/render3/util";
import { EventEmitter } from "protractor";
import { ErrorComponent } from "src/app/error/error.component";
import jwt_decode from "jwt-decode";
import { DataLayerService } from "src/app/core-module/services/data-layer.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-selecting-device-modal",
  templateUrl: "./selecting-device-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./selecting-device-modal.component.scss"],
})
export class SelectingDeviceModalComponent implements OnInit, OnDestroy {
  modalOptions: NgbModalOptions;

  @Input() public device;
  @Input() public selectedComparingDevices;
  @Input() public hasSuccessfulFeatures: boolean;
  callConfigureMethod;
  devicePopup;
  selectedDevices;
  addColumn: any = [];
  storedColumnsAPI: any = [];
  columnSelection: FormGroup;
  isFeatureSelected;
  storeFilterColumns;
  hide12nc = true;
  hideDeviceName = true;
  hideDatasheetLink = true;
  hideApplication = true;
  hidePout = true;
  hideIoutMin = true;
  hideIoutMax = true;
  hideInterface = true;
  hideDimming = true;
  hideEfficiency = true;
  hideIsolation = true;
  hideMounting = true;
  hideLength = true;
  hideWidth = true;
  hideHeight = true;
  hideSimpleSetMultiDeviceConfiguration = true;
  hideVoutMin = true;
  hideVoutMax = true;
  hideSelectAll;
  isDisbaleBtn = false;
  countColumns = 0;
  columnList = [
    "12nc",
    "deviceName",
    "datasheet",
    "applicationArea",
    "pOut",
    "iOutMin",
    "iOutMax",
    "vOutMax",
    "vOutMin",
    "configInterface",
    "dimming",
    "efficiency",
    "isolation",
    "moutning",
    "length",
    "width",
    "height",
    "SimpleSetBoxConfiguration",
  ];

  configurationData;
  softwareVer: any = [];
  softwareVersions = {};
  compareSoftwareVer: any = [];
  deviceFirmwareObj;
  latestFirmware;
  compareSelectFirmWare;
  multipleFirmwareVersions: any = [];

  device12nc;
  loading: boolean = false;
  envHostName;
  preferencesAPIHostName;
  preferencesAPI = "config/api/v1/preferences";
  fileName;
  fileContent;
  decryptData;
  isErrorPopup;
  uploadFeature;

  unsubscribe = new Subject<any>();

  envStartConfigURL;
  startConfigurationData = {};
  feature12NcName: any = [];
  envScehmaLIstApi;
  schemaResp: any;
  softwareVersionSchema;
  luminSoftwareVersionSchema: any;
  mtpSoftwareVersionSchema: any;
  dynadimmerSoftwareVersionSchema: any;
  dcemergencySoftwareVersionSchema: any;
  ampdimSoftwareVersionSchema: any;
  ztvSoftwareVersionSchema: any;
  ztvoutputSoftwareVersionSchema: any;
  lipSoftwareVersionSchema: any;
  rsoSoftwareVersionSchema: any;
  touchdimSoftwareVersionSchema: any;
  diia253maintenanceSoftwareVersionSchema: any;
  corridorSoftwareVersionSchema: any;
  aocSoftwareVersionSchema: any;
  astSoftwareVersionSchema: any;
  cloliteSoftwareVersionSchema: any;
  cloSoftwareVersionSchema: any;
  dimminginterfaceSoftwareVersionSchema: any;
  multiconfigSoftWareVersionSchema: any;
  lineswitchSoftwareVersionSchema: any;
  owpSoftwareVersionSchema: any;
  codedmainsSoftwareVersionSchema: any;
  dalipsuSoftwareVersionSchema: any;
  oldDeviceName: any;
  newDeviceName: any;
  oldDevice: any;
  compatibleFeatures: any;
  popup: string;
  softwareVerTrim: any;
  comparesoftwareVerTrim: any = [];
  storeConfigData: any;
  softwareVersion1: any;
  dtlSoftwareVersionSchema: any;
  flextuneSoftwareVersionSchema: any;
  mindimlevelSoftwareVersionSchema: any;
  latestDeviceVersion: any = [];
  latestDvValue: any;
  softwareVerTrimArray: any = ["name", "deviceVersion"];
  swvalue: any = 0;
  ngSelect: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private service: ApiService,
    private fb: FormBuilder,
    public route: Router,
    private _dataLayerService: DataLayerService
  ) {}

  ngOnInit() {
    this.columnSelection = this.fb.group({
      filterColumns: this.fb.array([]),
    });
    this.service.sendCloseSelectDevicePopup("");
    this.service.checkCloseSelectDevicePopup
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp) => {
        if (resp == "close") {
          this.activeModal.close();
        }
      });
    this.service.getconfigData.subscribe((resp) => {
      this.devicePopup = resp;
    });

    this.service.getSelectedModal.subscribe((resp) => {
      this.popup = resp;
    });

    if (this.popup === "newDevicePartial") {
      this.oldDevice = JSON.parse(localStorage.getItem("oldConfigurationData"));
      this.oldDeviceName = this.oldDevice["deviceName"];
      this.compatibleFeatures = JSON.parse(localStorage.getItem("compatible"));
      for (let i = 0; i < this.compatibleFeatures.length; i++) {
        var index = this.compatibleFeatures.indexOf("Dynadim");
        if (index !== -1) {
          this.compatibleFeatures.splice(index, 1);
        }

        var index2 = this.compatibleFeatures.indexOf("Mindimlevel");
        if (index2 !== -1) {
          this.compatibleFeatures.splice(index2, 1);
        }
        var index3 = this.compatibleFeatures.indexOf("Dtl");
        if (index3 !== -1) {
          this.compatibleFeatures.splice(index3, 1);
        }
        var index5 = this.compatibleFeatures.indexOf("Dimminginterface");
        if (index5 !== -1) {
          this.compatibleFeatures.splice(index5, 1);
        }
      }
      for (let i = 0; i < this.compatibleFeatures.length; i++) {
        var index = this.compatibleFeatures.indexOf("Aoc");

        if (index !== -1) {
          this.compatibleFeatures[index] = "AOC";
        }
        var index1 = this.compatibleFeatures.indexOf("Alo");

        if (index1 !== -1) {
          this.compatibleFeatures[index1] = "ALO";
        }
        var index2 = this.compatibleFeatures.indexOf("Clo");

        if (index2 !== -1) {
          this.compatibleFeatures[index2] = "CLO";
        }
        var index3 = this.compatibleFeatures.indexOf("Clolite");

        if (index3 !== -1) {
          this.compatibleFeatures[index3] = "CLOLite";
        }
        var index4 = this.compatibleFeatures.indexOf("Ast");

        if (index4 !== -1) {
          this.compatibleFeatures[index4] = "0-10V / 1-10V";
        }
        var index5 = this.compatibleFeatures.indexOf("SrPsu");

        if (index5 !== -1) {
          this.compatibleFeatures[index5] = "SR PSU";
        }
        var index6 = this.compatibleFeatures.indexOf("Lineswitch");
        if (index6 !== -1) {
          this.compatibleFeatures[index6] = "Line Switch";
        }
        var index7 = this.compatibleFeatures.indexOf("ampdim");

        if (index7 !== -1) {
          this.compatibleFeatures[index7] = "Amp Dim";
        }
        var index8 = this.compatibleFeatures.indexOf("Dcemergency");

        if (index8 !== -1) {
          this.compatibleFeatures[index8] = "DC Emergency";
        }
        var index9 = this.compatibleFeatures.indexOf("Dynadimmer");

        if (index9 !== -1) {
          this.compatibleFeatures[index9] = "Dyna Dimmer";
        }
        var index10 = this.compatibleFeatures.indexOf("Touchdim");
        if (index10 !== -1) {
          this.compatibleFeatures[index10] = "Touch and Dim";
        }
      }
      this.newDeviceName = this.device.deviceName;
    }
    if (this.popup === "newDevicenotSupport") {
      this.oldDevice = JSON.parse(localStorage.getItem("oldConfigurationData"));
      this.oldDeviceName = this.oldDevice["deviceName"];
      this.newDeviceName = this.device.deviceName;
    }
    this.service.getValidation.subscribe((resp) => {
      if (resp === "success") {
        let tmp = [];
        this.uploadFeature = JSON.parse(
          localStorage.getItem("importFeatureData")
        );
        this.uploadFeature.successfulFeatures.forEach((element) => {
          if (element === "ALOMin") {
            const index =
              this.uploadFeature.successfulFeatures.indexOf("ALOMin");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "ALO";
            }
          } else if (element === "Ztv") {
            const index = this.uploadFeature.successfulFeatures.indexOf("Ztv");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "0-10V / 1-10V";
            }
          } else if (element === "Ast") {
            const index = this.uploadFeature.successfulFeatures.indexOf("Ast");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "Startup time";
            }
          } else if (element === "LumInfoFormat") {
            const index =
              this.uploadFeature.successfulFeatures.indexOf("LumInfoFormat");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "Luminaire info";
            }
          } else if (element === "CLOLite") {
            const index =
              this.uploadFeature.successfulFeatures.indexOf("CLOLite");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "CLO";
            }
          } else if (element === "SrPsu") {
            const index =
              this.uploadFeature.successfulFeatures.indexOf("SrPsu");
            if (index !== -1) {
              this.uploadFeature.successfulFeatures[index] = "SR PSU";
            }
          }
        });
        this.uploadFeature.successfulFeatures =
          this.uploadFeature.successfulFeatures.sort();
        this.isErrorPopup = true;
      } else {
        this.isErrorPopup = false;
      }
    });
    if (localStorage.getItem("saveFileName")) {
      this.fileName = localStorage.getItem("saveFileName");
    }
    if (localStorage.getItem("decryptData")) {
      this.decryptData = JSON.parse(localStorage.getItem("decryptData"));
      if (this.decryptData.deviceSoftwareVersion.length > 3) {
        this.decryptData.deviceSoftwareVersion =
          this.decryptData.deviceSoftwareVersion.slice(0, -2);
      }
    }
    if (localStorage.getItem("saveFileContent")) {
      this.fileContent = localStorage.getItem("saveFileContent");
    }
    if (localStorage.getItem("device12nc")) {
      this.device12nc = JSON.parse(localStorage.getItem("device12nc") || "{}");
    } else {
      if (this.device) {
        this.device12nc = this.device["device12nc"];
      }
    }

    if (this.devicePopup != "searchNewDevice") {
      this.loading = true;
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            this.unsubscribe.next();
            this.envHostName = url;
            this.getDeviceSoftwareVersion(this.device12nc).subscribe(
              (deviceVersion) => {
                let versions = [];
                
                  for (let i = 0; i < deviceVersion["items"].length; i++) {
                    let devVersion = deviceVersion['items'][i].deviceType.nameAndVersion.split(":")[2];
                    let itemversions = deviceVersion['items'][i].softwareVersions.map(ver => 
                      {
                        let version = ver.split(".").slice(0,2).join(".");
                        return {
                          version,
                          selected: false,
                          devVersion
                        }
                      });
                      versions = [...versions, ...itemversions]
                  }
                  if(versions.length > 0) {
                    versions[0].selected = true;
                    this.latestFirmware = versions[0].version;
                    localStorage.setItem(
                      "comparefirmwarenoLatest",
                      JSON.stringify(this.latestFirmware)
                    );
                  }
                  this.softwareVersions[this.device12nc] = { 
                    versions: versions
                  }
                  this.loading = false;
              }
            );
          }
        });
    }
    this.loading = true;
    if (this.selectedComparingDevices) {
      this.service.getEnvValue();
      let first = true;
      this.service.getEnvData.subscribe((url) => {
        if (url.length && first) {
          this.unsubscribe.next();
          this.envHostName = url;
          if (this.selectedComparingDevices.length !== 0) {
            this.selectedComparingDevices.forEach((individualDevice) => {
              this.device12nc = individualDevice.device12nc;
              this.getDeviceSoftwareVersion(this.device12nc).subscribe(
                (deviceVersion) => {
                  let versions = [];
                  
                  for (let i = 0; i < deviceVersion["items"].length; i++) {
                    let devVersion = deviceVersion['items'][i].deviceType.nameAndVersion.split(":")[2];
                    let itemversions = deviceVersion['items'][i].softwareVersions.map(ver => 
                      {
                        let version = ver.split(".").slice(0,2).join(".");
                        return {
                          version,
                          selected: false,
                          devVersion
                        }
                      });
                      versions = [...versions, ...itemversions];
                      
                  }
                  if(versions.length > 0) {
                    versions[0].selected = true;
                    this.latestFirmware = versions[0].version;
                    localStorage.setItem(
                      "comparefirmwarenoLatest",
                      JSON.stringify(this.latestFirmware)
                    );
                  }
                  this.softwareVersions[individualDevice.device12nc] = { 
                    versions: versions,
                    
                  }
                  this.loading=false;
                }
              );
            });
          }
          first = false;
        }
      });
    }

    if (this.device) {
      var domain = "https://www.api.easydesignintool.signify.com";
      this.device.devicetypePicture = this.device.devicetypePicture
        ? this.device.devicetypePicture
        : this.device.picture;
      if (this.device.devicetypePicture !== undefined) {
        this.device.devicetypePicture =
          domain + this.device.devicetypePicture.replace(domain, "");
      }
    }

    var apiStoreColumnsData;
    this.service.getColumnSelection.subscribe((data) => {
      apiStoreColumnsData = data;
    });

    if (!apiStoreColumnsData) {
      if (localStorage.getItem("storeColumn")) {
        let storageData = JSON.parse(
          localStorage.getItem("storeColumn") || "{}"
        );
        apiStoreColumnsData = storageData["storeColumn"];
        let obj = {};
        if (storageData["storeColumnsCheck"] == false) {
          apiStoreColumnsData = this.columnList;
        }
        obj["storeColumnsData"] = apiStoreColumnsData;
        obj["storeColumnsCheck"] = storageData["storeColumnsCheck"];
        localStorage.setItem("storeColumn", JSON.stringify(obj));
      }
    }

    if (apiStoreColumnsData) {
      this.hideDeviceName = false;
      this.hide12nc = false;
      this.hideDatasheetLink = false;
      this.hideApplication = false;
      this.hidePout = false;
      this.hideIoutMin = false;
      this.hideIoutMax = false;
      this.hideVoutMin = false;
      this.hideVoutMax = false;
      this.hideInterface = false;
      this.hideDimming = false;
      this.hideEfficiency = false;
      this.hideIsolation = false;
      this.hideMounting = false;
      this.hideLength = false;
      this.hideWidth = false;
      this.hideHeight = false;
      this.hideSimpleSetMultiDeviceConfiguration = false;
      this.storeFilterColumns = apiStoreColumnsData;
      let count = 0;
      this.storeFilterColumns.filter((data) => {
        count = count + 1;

        if (data === "12nc") {
          this.hide12nc = true;
        }
        if (data === "deviceName") {
          this.hideDeviceName = true;
        }
        if (data === "datasheet") {
          this.hideDatasheetLink = true;
        }
        if (data === "applicationArea") {
          this.hideApplication = true;
        }
        if (data === "pOut") {
          this.hidePout = true;
        }
        if (data === "iOutMin") {
          this.hideIoutMin = true;
        }
        if (data === "iOutMax") {
          this.hideIoutMax = true;
        }
        if (data === "vOutMax") {
          this.hideVoutMax = true;
        }
        if (data === "vOutMin") {
          this.hideVoutMin = true;
        }
        if (data === "configInterface") {
          this.hideInterface = true;
        }
        if (data === "dimming") {
          this.hideDimming = true;
        }
        if (data === "efficiency") {
          this.hideEfficiency = true;
        }
        if (data === "isolation") {
          this.hideIsolation = true;
        }
        if (data === "moutning") {
          this.hideMounting = true;
        }
        if (data === "length") {
          this.hideLength = true;
        }
        if (data === "width") {
          this.hideWidth = true;
        }
        if (data === "height") {
          this.hideHeight = true;
        }
        if (data === "SimpleSetBoxConfiguration") {
          this.hideSimpleSetMultiDeviceConfiguration = true;
        }
        this.selectColumn(data, true);
        if (count == 18) {
          this.hideSelectAll = true;
        } else {
          this.hideSelectAll = false;
        }
      });
    } else {
      this.selectAll(true);
    }
    // this.hideSelectAll = true;
    this.isFeatureSelected = this.columnSelection.value.filterColumns.length;
  }

  selectVersion( version, nc12){
    this.softwareVersions[nc12].versions.forEach(ver => {
      ver.selected = ver.version == version;
      if(ver.selected) {
        this.latestFirmware = version;
        localStorage.setItem(
          "comparefirmwarenoLatest",
          JSON.stringify(this.latestFirmware)
        );
      }
    });
  }

  configDevice() {
    this.buildConfigurtaionDataObj(this.device);
    let data = JSON.parse(localStorage.getItem("readerDeviceFullInfo"));
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
    this._dataLayerService.logUserDetails({
      UserId: localStorage.getItem("UserId"),
      companyId: decodedObj.company["companyId"],
      companyName: decodedObj.company["companyName"],
      userRole: decodedObj.role,
    });
    this._dataLayerService.resetSetSelectedDeviceInfo();
    this._dataLayerService.setSelectedDeviceInfo({
      productName: data[0].deviceName,
      "12NC": data[0].device12nc,
      familyTopLevel: data[0].family,
      firmwareno: this.latestFirmware,
    });
  }

  selectingDevice(selectedDevice) {
    this.loading = true;
    localStorage.removeItem("device12nc");
    this.selectedDevices = selectedDevice;
    let storeConfigMode = JSON.parse(localStorage.getItem("storeConfigMode"));
    let storeOmitOption = JSON.parse(localStorage.getItem("storeOmitOption"));
    let exportedFeatureFile = JSON.parse(
      localStorage.getItem("exportedFeatureFile")
    );
    // && (storeOmitOption && storeOmitOption['storeDevicePopupValue'] == "omit" && storeOmitOption['storeDevicePopupCheck'] == true)) {
    this.feature12NcName = [];
    this.buildConfigurtaionDataObj(this.selectedDevices);
  }

  selectColumn(tableName, e) {
    if (e) {
      if (tableName === "12nc") {
        this.hide12nc = true;
      }
      if (tableName === "deviceName") {
        this.hideDeviceName = true;
      }
      if (tableName === "datasheet") {
        this.hideDatasheetLink = true;
      }
      if (tableName === "applicationArea") {
        this.hideApplication = true;
      }
      if (tableName === "pOut") {
        this.hidePout = true;
      }
      if (tableName === "iOutMin") {
        this.hideIoutMin = true;
      }
      if (tableName === "iOutMax") {
        this.hideIoutMax = true;
      }
      if (tableName === "vOutMax") {
        this.hideVoutMax = true;
      }
      if (tableName === "vOutMin") {
        this.hideVoutMin = true;
      }
      if (tableName === "configInterface") {
        this.hideInterface = true;
      }
      if (tableName === "dimming") {
        this.hideDimming = true;
      }
      if (tableName === "efficiency") {
        this.hideEfficiency = true;
      }
      if (tableName === "isolation") {
        this.hideIsolation = true;
      }
      if (tableName === "moutning") {
        this.hideMounting = true;
      }
      if (tableName === "length") {
        this.hideLength = true;
      }
      if (tableName === "width") {
        this.hideWidth = true;
      }
      if (tableName === "height") {
        this.hideHeight = true;
      }
      if (tableName === "SimpleSetBoxConfiguration") {
        this.hideSimpleSetMultiDeviceConfiguration = true;
      }
    } else {
      if (tableName === "12nc") {
        this.hide12nc = false;
      }
      if (tableName === "deviceName") {
        this.hideDeviceName = false;
      }
      if (tableName === "datasheet") {
        this.hideDatasheetLink = false;
      }
      if (tableName === "applicationArea") {
        this.hideApplication = false;
      }
      if (tableName === "pOut") {
        this.hidePout = false;
      }
      if (tableName === "iOutMin") {
        this.hideIoutMin = false;
      }
      if (tableName === "iOutMax") {
        this.hideIoutMax = false;
      }
      if (tableName === "vOutMax") {
        this.hideVoutMax = false;
      }
      if (tableName === "vOutMin") {
        this.hideVoutMin = false;
      }
      if (tableName === "configInterface") {
        this.hideInterface = false;
      }
      if (tableName === "dimming") {
        this.hideDimming = false;
      }
      if (tableName === "efficiency") {
        this.hideEfficiency = false;
      }
      if (tableName === "isolation") {
        this.hideIsolation = false;
      }
      if (tableName === "moutning") {
        this.hideMounting = false;
      }
      if (tableName === "length") {
        this.hideLength = false;
      }
      if (tableName === "width") {
        this.hideWidth = false;
      }
      if (tableName === "height") {
        this.hideHeight = false;
      }
      if (tableName === "SimpleSetBoxConfiguration") {
        this.hideSimpleSetMultiDeviceConfiguration = false;
      }
    }
    const selectedColumnArray = <FormArray>(
      this.columnSelection.controls.filterColumns
    );
    if (e) {
      selectedColumnArray.push(new FormControl(tableName));
      if (selectedColumnArray.length == 18) {
        this.hideSelectAll = true;
      }
      this.countColumns = selectedColumnArray.length;
    } else {
      let index = selectedColumnArray.controls.findIndex(
        (x) => x.value === tableName
      );
      selectedColumnArray.removeAt(index);
      this.hideSelectAll = false;
      this.countColumns = selectedColumnArray.length;
    }
    if (selectedColumnArray.length < 1) {
      this.isDisbaleBtn = true;
    } else {
      this.isDisbaleBtn = false;
    }
    this.isFeatureSelected = this.columnSelection.value.filterColumns.length;
  }

  selectAll(e) {
    this.columnSelection = this.fb.group({
      filterColumns: this.fb.array([]),
    });
    if (e) {
      this.hideSelectAll = true;
      this.columnList.forEach((element) => {
        this.selectColumn(element, true);
      });
    } else {
      this.hideSelectAll = false;
      this.columnList.forEach((element) => {
        this.selectColumn(element, false);
      });
    }
  }

  onSubmit() {
    let tmp = [];
    for (let i = 0; i < this.columnSelection.value.filterColumns.length; i++) {
      if (tmp.indexOf(this.columnSelection.value.filterColumns[i]) === -1) {
        tmp.push(this.columnSelection.value.filterColumns[i]);
      }
    }
    this.addColumn = tmp;
    ///to change array order
    var reference_object = {};
    for (var i = 0; i < this.columnList.length; i++) {
      reference_object[this.columnList[i]] = i;
    }
    if (this.columnList.length > 0) {
      this.addColumn.sort(function (a, b) {
        ///to change array order
        return reference_object[a] - reference_object[b];
      });
    }

    // localStorage.setItem('storeColumn', JSON.stringify(this.addColumn));
    let storeColumn = JSON.parse(localStorage.getItem("storeColumn") || "{}");
    let sortingObjectKey = new Object();
    sortingObjectKey["storeColumnsData"] = this.addColumn;
    if (storeColumn && storeColumn["storeColumnsCheck"]) {
      sortingObjectKey["storeColumnsCheck"] = storeColumn["storeColumnsCheck"];
    } else {
      sortingObjectKey["storeColumnsCheck"] = false;
    }
    localStorage.setItem("storeColumn", JSON.stringify(sortingObjectKey));
    this.service.postCallForUserPreferences();
    this.service.setColumn(sortingObjectKey["storeColumnsData"]);
    this.activeModal.close();
    this.route.navigate(["/deviceList"]);
  }

  getDeviceSoftwareVersion(device12Nc) {
    return this.service.getDeviceSoftwareVersion(this.envHostName, device12Nc);
  }

  buildConfigurtaionDataObj(deviceArg) {
    this.service.getEnvValue();
    // setTimeout( () => {
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.loading = true;
          this.unsubscribe.next();
          this.latestFirmware = JSON.parse(
            localStorage.getItem("comparefirmwarenoLatest")
          );
          this.envHostName = url;
          this.envScehmaLIstApi = url;
          let selectedVersion = this.softwareVersions[deviceArg.device12nc].versions.find(ver => ver.selected);
          this.schemalistApi(
            deviceArg.device12nc,
            selectedVersion.devVersion
          ).subscribe((resp) => {
            let unSupportedFeatCheck = resp["metadata"].schemas;
            let tags = resp["metadata"].tags;
            if(tags && tags.length > 0){
              localStorage.setItem('tags', JSON.stringify(tags[0]))
            }else{
              localStorage.setItem('tags', null);
            }
            let supportedSchema: string[] = [];
            for (
              let index = 0;
              index < unSupportedFeatCheck.length;
              index++
            ) {
              const element = unSupportedFeatCheck[index];
              // if (
              //   !(element.includes("dynadimmer")
              //   || element.includes("lineswitch")
              //   || element.includes("codedmains")
              //   || element.includes("ampdim")
              //   || element.includes("dimminginterface")
              //   || element.includes("owp")
              // )) {
              supportedSchema.push(element);
              //}
            } //MOWEB 1.1.3 Release - Temp Update
            resp["metadata"].schemas = supportedSchema;
            if (resp["metadata"].schemas.length) {
              this.schemaResp = resp["metadata"].schemas;
              
              this.schemaResp.forEach((element, index) => {
                let birth =
                  element.match(/[^,]*mcsa-[^,]*/g) ||
                  element.match(/[^,]*birth-[^,]*/g) ||
                  element.match(/[^,]*public-[^,]*/g) ||
                  element.match(/[^,]*bl[^,]*/g) ||
                  element.match(/[^,]*''[^,]*/g);
                if (!birth) {
                  var splitted = element.split(":")[1];
                  if (splitted) {
                    var feature12NCvalue = splitted.substring(
                      0,
                      splitted.length - deviceArg.device12nc.length
                    );
                    this.feature12NcName.push(feature12NCvalue);
                  }
                }
              });
              this.feature12NcName = this.feature12NcName.filter(Boolean);
              this.feature12NcName.forEach((element) => {
                //if(element === 'owp-' || element === 'dynadimmer-' || element === 'lineswitch-') {
                // if (element === "owp-") {
                //   this.feature12NcName.splice(
                //     this.feature12NcName.indexOf(element),
                //     1
                //   );
                // }
              });
              let val = this.schemaResp.toString();
              let versiontoString =
                val.match(/[^,]*alo[^,]*/g) ||
                val.match(/[^,]*clo[^,]*/g) ||
                val.match(/[^,]*aoc[^,]*/g) ||
                val.match(/[^,]*ztv[^,]*/g) ||
                val.match(/[^,]*ast[^,]*/g) ||
                val.match(/[^,]*luminfoformat0[^,]*/g) ||
                val.match(/[^,]*srpsu[^,]*/g);
              this.softwareVersionSchema = versiontoString
                .toString()
                .split(":")[2];
              let selectedFeaturesInfo = [];
              //  selectedFeaturesInfo.push('0-10v / 1-10v', 'Alo', 'Aoc', 'Clo', 'CloLite', 'Startup time', 'Ztv');
              if (val.match(/[^,]*multiconfigmode[^,]*/g)) {
                selectedFeaturesInfo.push("Multiconfig");
                let multiconfigversiontoString = val.match(
                  /[^,]*multiconfigmode[^,]*/g
                );
                this.multiconfigSoftWareVersionSchema =
                multiconfigversiontoString.toString().split(":")[2];
              }
              
              if (val.match(/[^,]*alo[^,]*/g)) {
                selectedFeaturesInfo.push("Alo");
              }
              if (val.match(/[^,]*clo-[^,]*/g)) {
                if (
                  val
                    .match(/[^,]*clo-[^,]*/g)
                    .toString()
                    .split(":")[1]
                    .slice(
                      0,
                      val
                        .match(/[^,]*clo-[^,]*/g)
                        .toString()
                        .split(":")[1]
                        .indexOf(deviceArg.device12nc)
                    ) === "clo-"
                ) {
                  selectedFeaturesInfo.push("Clo");
                  let cloversiontoString = val.match(/[^,]*clo-[^,]*/g);
                  this.cloSoftwareVersionSchema = cloversiontoString
                    .toString()
                    .split(":")[2];
                }
              }
              if (val.match(/[^,]*aoc[^,]*/g)) {
                selectedFeaturesInfo.push("Aoc");
                let aocversiontoString = val.match(/[^,]*aoc[^,]*/g);
                this.aocSoftwareVersionSchema = aocversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*touchdim[^,]*/g)) {
                let touchdimversiontoString =
                  val.match(/[^,]*touchdim[^,]*/g);
                this.touchdimSoftwareVersionSchema = touchdimversiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("touchdim");
              }
              if (val.match(/[^,]*diia253maintenance[^,]*/g)) {
                let diia253maintenanceversiontoString = val.match(
                  /[^,]*diia253maintenance[^,]*/g
                );
                this.diia253maintenanceSoftwareVersionSchema =
                  diia253maintenanceversiontoString
                    .toString()
                    .split(":")[2];
                selectedFeaturesInfo.push("diia253maintenance");
              }
              if (val.match(/[^,]*ast[^,]*/g)) {
                selectedFeaturesInfo.push("Startup time");
                let astversiontoString = val.match(/[^,]*ast[^,]*/g);
                this.astSoftwareVersionSchema = astversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*dimminginterface[^,]*/g)) {
                selectedFeaturesInfo.push("Dimminginterface");
                let dimminginterfaceversiontoString = val.match(
                  /[^,]*dimminginterface[^,]*/g
                );
                this.dimminginterfaceSoftwareVersionSchema =
                  dimminginterfaceversiontoString.toString().split(":")[2];
              }
              if (val.match(/[^,]*mtp[^,]*/g)) {
                selectedFeaturesInfo.push("Mtp");
                let mtpversiontoString = val.match(/[^,]*mtp[^,]*/g);
                this.mtpSoftwareVersionSchema = mtpversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*srpsu[^,]*/g)) {
                selectedFeaturesInfo.push("SR PSU");
              }
              if (val.match(/[^,]*luminfo[^,]*/g)) {
                selectedFeaturesInfo.push("LuminInfo");
                let luminversiontoString = val.match(/[^,]*luminfo[^,]*/g);
                this.luminSoftwareVersionSchema = luminversiontoString
                  .toString()
                  .split(":")[2];
                // this.isLuminaireInfo=1;
              }
              if (val.match(/[^,]*ztv[^,]*/g)) {
                selectedFeaturesInfo.push("0-10v / 1-10v");
                let ztvversiontoString = val.match(/[^,]*ztv[^,]*/g);
                this.ztvSoftwareVersionSchema = ztvversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*ztvoutput[^,]*/g)) {
                selectedFeaturesInfo.push("ztvoutput");
                let ztvoutputversiontoString =
                  val.match(/[^,]*ztvoutput[^,]*/g);
                this.ztvoutputSoftwareVersionSchema =
                  ztvoutputversiontoString.toString().split(":")[2];
              }
              if (val.match(/[^,]*lip[^,]*/g)) {
                selectedFeaturesInfo.push("lip");
                let lipversiontoString = val.match(/[^,]*lip[^,]*/g);
                if (lipversiontoString.length > 1) {
                  this.lipSoftwareVersionSchema = lipversiontoString[1]
                    .toString()
                    .split(":")[2];
                } else {
                  this.lipSoftwareVersionSchema = lipversiontoString
                    .toString()
                    .split(":")[2];
                }
              }
              if (val.match(/[^,]*rso[^,]*/g)) {
                selectedFeaturesInfo.push("rso");
                let rsoversiontoString = val.match(/[^,]*rso[^,]*/g);
                this.rsoSoftwareVersionSchema = rsoversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*clolite[^,]*/g)) {
                selectedFeaturesInfo.push("CloLite");
                let cloliteversiontoString =
                  val.match(/[^,]*clolite[^,]*/g);
                this.cloliteSoftwareVersionSchema = cloliteversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*corridor-[^,]*/g)) {
                let corridorversiontoString =
                  val.match(/[^,]*corridor[^,]*/g);
                this.corridorSoftwareVersionSchema = corridorversiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("Corridor");
              }
              if (val.match(/[^,]*dcemergency-[^,]*/g)) {
                let dcemergencyVersiontoString = val.match(
                  /[^,]*dcemergency-[^,]*/g
                );
                this.dcemergencySoftwareVersionSchema =
                  dcemergencyVersiontoString.toString().split(":")[2];
                selectedFeaturesInfo.push("DC Emergency");
              }
              if (val.match(/[^,]*touchdim-[^,]*/g)) {
                let touchdimversiontoString =
                  val.match(/[^,]*touchdim[^,]*/g);
                this.touchdimSoftwareVersionSchema = touchdimversiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("Touch and Dim");
              }
              if (val.match(/[^,]*diia253maintenance-[^,]*/g)) {
                let diia253maintenanceversiontoString = val.match(
                  /[^,]*diia253maintenance[^,]*/g
                );
                this.diia253maintenanceSoftwareVersionSchema =
                  diia253maintenanceversiontoString
                    .toString()
                    .split(":")[2];
                selectedFeaturesInfo.push("diia253maintenance");
              }
              if (val.match(/[^,]*dynadimmer-[^,]*/g)) {
                let dynadimmerVersiontoString = val.match(
                  /[^,]*dynadimmer-[^,]*/g
                );
                this.dynadimmerSoftwareVersionSchema =
                  dynadimmerVersiontoString.toString().split(":")[2];
                selectedFeaturesInfo.push("Dyna Dimmer");
              }
              if (val.match(/[^,]*lineswitch-[^,]*/g)) {
                selectedFeaturesInfo.push("Line Switch");
                let lineswitchversiontoString = val.match(
                  /[^,]*lineswitch-[^,]*/g
                );
                this.lineswitchSoftwareVersionSchema =
                  lineswitchversiontoString.toString().split(":")[2];
              }
              if (val.match(/[^,]*owp-[^,]*/g)) {
                selectedFeaturesInfo.push("Owp");
                let owpversiontoString = val.match(/[^,]*owp-[^,]*/g);
                this.owpSoftwareVersionSchema = owpversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*codedmains-[^,]*/g)) {
                selectedFeaturesInfo.push("Codedmains");
                let codedmainsversiontoString = val.match(
                  /[^,]*codedmains-[^,]*/g
                );
                this.codedmainsSoftwareVersionSchema =
                  codedmainsversiontoString.toString().split(":")[2];
              }
              if (val.match(/[^,]*dalipsu-[^,]*/g)) {
                let dalipsuversiontoString =
                  val.match(/[^,]*dalipsu-[^,]*/g);
                this.dalipsuSoftwareVersionSchema = dalipsuversiontoString
                  .toString()
                  .split(":")[2];
              }
              if (val.match(/[^,]*ampdim-[^,]*/g)) {
                let ampdimVersiontoString = val.match(/[^,]*ampdim[^,]*/g);
                this.ampdimSoftwareVersionSchema = ampdimVersiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("Amp Dim");
              }
              if (val.match(/[^,]*dtl[^,]*/g)) {
                let dtlVersiontoString = val.match(/[^,]*dtl[^,]*/g);
                this.dtlSoftwareVersionSchema = dtlVersiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("DTL");
              }
              if (val.match(/[^,]*flextune[^,]*/g)) {
                let flextuneVersiontoString =
                  val.match(/[^,]*flextune[^,]*/g);
                this.flextuneSoftwareVersionSchema = flextuneVersiontoString
                  .toString()
                  .split(":")[2];
                selectedFeaturesInfo.push("Flextune");
              }
              if (val.match(/[^,]*mindimlevel[^,]*/g)) {
                let mindimlevelVersiontoString = val.match(
                  /[^,]*mindimlevel[^,]*/g
                );
                this.mindimlevelSoftwareVersionSchema =
                  mindimlevelVersiontoString.toString().split(":")[2];
                selectedFeaturesInfo.push("Min Dim Level");
              }
              this.service.getEnvValue();
              this.service.getEnvData
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((url) => {
                  if (url.length) {
                    this.unsubscribe.next();
                    this.envStartConfigURL = url;
                    // till demo purpose
                    let mockDevice = {};
                    if (this.latestFirmware.length > 3) {
                      this.latestFirmware = this.latestFirmware.slice(
                        0,
                        -2
                      );
                    }
                    if (deviceArg.deviceName === "XH075C120V075KPF1") {
                      mockDevice = {
                        device12nc: deviceArg.device12nc,
                        softwareVersion: this.latestFirmware,
                      };
                    } else {
                      mockDevice = {
                        device12nc: deviceArg.device12nc,
                        deviceName: deviceArg.deviceName,
                        softwareVersion: this.latestFirmware,
                      };
                    }
                    this.service
                      .getStartConfiguration(
                        mockDevice,
                        this.envStartConfigURL
                      )
                      .subscribe(
                        (data) => {
                          localStorage.removeItem("deviceVersion");
                          var configurationID;
                          if (localStorage.getItem("configId")) {
                            configurationID =
                              localStorage.getItem("configId");
                          } else {
                            configurationID = data["configurationID"];
                          }
                          let feature12Nc = [
                            ...new Set(this.feature12NcName),
                          ];
                          this.startConfigurationData["configurationID"] =
                            configurationID;
                          this.startConfigurationData["deviceName"] =
                            deviceArg["deviceName"];
                          this.startConfigurationData["softwareVersion"] =
                            deviceArg["softwareVersion"];
                          this.startConfigurationData["picture"] =
                            deviceArg["devicetypePicture"];
                          this.startConfigurationData[
                            "selectedFeaturesInfo"
                          ] = [];
                          this.startConfigurationData["luminSoftVer"] =
                            this.luminSoftwareVersionSchema;
                          this.startConfigurationData["dtlSoftVer"] =
                            this.dtlSoftwareVersionSchema;
                          this.startConfigurationData["flextuneSoftVer"] =
                            this.flextuneSoftwareVersionSchema;
                          this.startConfigurationData[
                            "mindimlevelSoftVer"
                          ] = this.mindimlevelSoftwareVersionSchema;
                          this.startConfigurationData["ztvSoftVer"] =
                            this.ztvSoftwareVersionSchema;
                          this.startConfigurationData["ztvoutputSoftVer"] =
                            this.ztvoutputSoftwareVersionSchema;
                          this.startConfigurationData["lipSoftVer"] =
                            this.lipSoftwareVersionSchema;
                          this.startConfigurationData["rsoSoftVer"] =
                            this.rsoSoftwareVersionSchema;
                          this.startConfigurationData["aocSoftVer"] =
                            this.aocSoftwareVersionSchema;
                          this.startConfigurationData["touchdimSoftVer"] =
                            this.touchdimSoftwareVersionSchema;
                          this.startConfigurationData[
                            "diia253maintenanceSoftVer"
                          ] = this.diia253maintenanceSoftwareVersionSchema;
                          this.startConfigurationData["corridorSoftVer"] =
                            this.corridorSoftwareVersionSchema;
                          this.startConfigurationData["astSoftVer"] =
                            this.astSoftwareVersionSchema;
                          this.startConfigurationData["cloliteSoftVer"] =
                            this.cloliteSoftwareVersionSchema;
                          this.startConfigurationData["cloSoftVer"] =
                            this.cloSoftwareVersionSchema;
                          this.startConfigurationData["softwareVerSchema"] =
                            this.softwareVersionSchema;
                          this.startConfigurationData["mtpSoftVer"] =
                            this.mtpSoftwareVersionSchema;
                          this.startConfigurationData["dynadimmerSoftVer"] =
                            this.dynadimmerSoftwareVersionSchema;
                          this.startConfigurationData[
                            "dcemergencySoftVer"
                          ] = this.dcemergencySoftwareVersionSchema;
                          this.startConfigurationData["ampdimSoftVer"] =
                            this.ampdimSoftwareVersionSchema;
                          this.startConfigurationData["lineswitchSoftVer"] =
                            this.lineswitchSoftwareVersionSchema;
                          this.startConfigurationData["owpSoftVer"] =
                            this.owpSoftwareVersionSchema;
                          this.startConfigurationData["codedmainsSoftVer"] =
                            this.codedmainsSoftwareVersionSchema;
                          this.startConfigurationData["dalipsuSoftVer"] =
                            this.dalipsuSoftwareVersionSchema;
                          this.startConfigurationData[
                            "dimminginterfaceSoftVer"
                          ] = this.dimminginterfaceSoftwareVersionSchema;
                          this.startConfigurationData[
                            "multiconfigSoftVer"
                          ] = this.multiconfigSoftWareVersionSchema;
                          this.startConfigurationData["device12nc"] =
                            deviceArg.device12nc;
                          this.startConfigurationData["feature12Nc"] =
                            feature12Nc;
                          localStorage.setItem(
                            "configurationData",
                            JSON.stringify(this.startConfigurationData)
                          );
                          this.service.setConfigurationData(
                            this.startConfigurationData
                          );
                          this.loading = false;
                          let mode = JSON.parse(
                            localStorage.getItem("storeConfigMode")
                          );
                          if (
                            mode["storeConfigModeCheck"] &&
                            (mode["storeConfigModeData"] === "Standard" ||
                              mode["storeConfigModeData"] === "quickConfig")
                          ) {
                            this.service.sendModes("Standard");
                          } else {
                            this.service.sendModes("Advanced");
                          }
                          localStorage.removeItem("deviceVersion");
                          this.activeModal.close();
                          this.service.sendDimmingCheckValidation("");
                          localStorage.setItem("fromDeviceList", "true");
                          this.route.navigate(["/featureLanding"]);
                          let configurationData = JSON.parse(
                            localStorage.getItem("configurationData")
                          );
                          let dataSet = [];
                          for (
                            var i = 0;
                            i < configurationData.feature12Nc.length;
                            i++
                          ) {
                            dataSet.push({
                              name: configurationData.feature12Nc[i].slice(
                                0,
                                -1
                              ),
                              available: "true",
                            });
                          }
                          let currentUser = JSON.parse(
                            localStorage.getItem("currentUser")
                          );
                          var decodedObj: any = jwt_decode(
                            JSON.stringify(currentUser)
                          );
                          this._dataLayerService.logUserDetails({
                            UserId: localStorage.getItem("UserId"),
                            companyId: decodedObj.company["companyId"],
                            companyName: decodedObj.company["companyName"],
                            userRole: decodedObj.role,
                          });
                          this._dataLayerService.setSelectedDeviceInfo({
                            event: "featureAvailable",
                            "12NC": configurationData.device12nc,
                            features: dataSet,
                          });
                        },
                        (error) => {
                          this.loading = false;
                          const modalRef = this.modalService.open(
                            ErrorComponent,
                            {
                              keyboard: false,
                              centered: true,
                              windowClass: "error-style, error-style1",
                            }
                          );
                          modalRef.componentInstance.errorName =
                            error["message"];
                          modalRef.componentInstance.errorDescription =
                            error["description"];
                        }
                      );
                  }
                });
            } else {
              this.activeModal.close();
              this.route.navigate(["/featureNonSupported"]);
            }
          });
        }
      });
  }

  schemalistApi(device12nc, deviceSoftwareVersion) {
    return this.service.getSupportedSchemaList(
      this.envScehmaLIstApi,
      device12nc,
      deviceSoftwareVersion
    );
  }

  confirming() {
    this.activeModal.close();
    localStorage.setItem("configurationData", JSON.stringify(this.device));
    this.storeConfigData = JSON.parse(localStorage.getItem("modes"));
    localStorage.setItem("closeConfigureModal", JSON.stringify(true));
    this.newConfigID();
  }

  callConfigdata() {
    localStorage.setItem("newDeviceBoolen", JSON.stringify(false));
    this.activeModal.close();
    this.service.sentdirectlyFeature("success");
    // setTimeout(()=>{
    //  this.isPageRefresh();
    // },5000);
  }

  newConfigID() {
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          if (localStorage.getItem("comparefirmwarenoLatest")) {
            this.softwareVersion1 = JSON.parse(
              localStorage.getItem("comparefirmwarenoLatest") || "{}"
            );
            if (this.softwareVersion1.length > 3) {
              this.softwareVersion1 = this.softwareVersion1.slice(0, -2);
            }
          }
          this.unsubscribe.next();
          this.envStartConfigURL = url;
          var arrayString = localStorage.getItem("configurationData");
          var a = JSON.parse(arrayString);
          let mockDevice = {
            device12nc: this.device.device12nc,
            deviceName: this.device.deviceName,
            softwareVersion: this.softwareVersion1,
          };
          this.service
            .getStartConfiguration(mockDevice, this.envStartConfigURL)
            .subscribe(
              (data) => {
                var configurationID;
                let deviceFullInfo = JSON.parse(
                  localStorage.getItem("readerDeviceFullInfo")
                );
                if (!isNullOrUndefined(deviceFullInfo)) {
                  localStorage.setItem(
                    "NFC_Protocol",
                    deviceFullInfo[0].configurationInterface
                  );
                }
                a["configurationID"] = data["configurationID"];
                if (this.softwareVersion1.length > 3) {
                  a["softwareVersion"] = this.softwareVersion1.slice(0, -2);
                }
                localStorage.setItem("normalFlow", JSON.stringify(true));
                localStorage.setItem("configurationData", JSON.stringify(a));
                localStorage.removeItem("saveReadValue");
                this.isPageRefresh();
              },
              (err) => {
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(error["description"]);
              }
            );
        }
      });
  }
  remove() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
    this._dataLayerService.logUserDetails({
      UserId: localStorage.getItem("UserId"),
      companyId: decodedObj.company["companyId"],
      companyName: decodedObj.company["companyName"],
      userRole: decodedObj.role,
    });
    this._dataLayerService.resetSetSelectedDeviceInfo();
  }
  isPageRefresh() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.service.callConfigMethod("");
  }

  otherDevice() {
    var oldData = JSON.parse(localStorage.getItem("oldConfigurationData"));
    localStorage.setItem("configurationData", JSON.stringify(oldData));
    this.service.disableBtn("confirm");
  }
}
